import React from 'react';

import { useRouter } from 'next/router';

import Trans from '@components/Trans';
import CustomLink from '@components/global/CustomLink';
import FormattedPrice from '@components/global/FormattedPrice';
import DeskoptBr from '@components/ui/DeskoptBr';
import { CTAStartFreeCreateAccount } from '@components/ui/design2023/CTAStartFreeCreateAccount';

import useTranslation from '@hooks/useTranslation';
import clsx from 'clsx';

import styles from './PricingBanner.module.css';

export interface PricingBannerProps {
    price: number | null;
    promoPrice: number | null;
    currency: string | null;
    isPromoActive?: boolean;
    sticky?: boolean;
}

const PricingBanner = ({ price = 0, promoPrice, currency, isPromoActive = false, sticky = true }: PricingBannerProps) => {
    const { t } = useTranslation('pages/homepage/2024/v1/index');
    const { locale } = useRouter();

    return (
        <aside className={clsx(styles.banner, sticky === true ? styles.sticky : '')}>
            <p>
                <Trans
                    i18nKey="pricingBanner"
                    components={{
                        price: (
                            <>
                                {isPromoActive && promoPrice ? (
                                    <>
                                        <FormattedPrice
                                            value={promoPrice}
                                            locale={locale?.replace('vn', 'vi')}
                                            currency={currency}
                                            period={t('pricingBannerPlan')}
                                            styles={{
                                                container: styles.promoPrice,
                                                currency: styles.priceCurrency,
                                                value: styles.priceValue,
                                                period: styles.pricePeriod,
                                            }}
                                        />
                                        <FormattedPrice
                                            value={price}
                                            locale={locale?.replace('vn', 'vi')}
                                            currency={currency}
                                            styles={{
                                                container: styles.oldPrice,
                                                currency: styles.oldPriceCurrency,
                                                value: styles.oldPriceValue,
                                            }}
                                        />
                                    </>
                                ) : (
                                    <FormattedPrice
                                        value={price}
                                        locale={locale?.replace('vn', 'vi')}
                                        currency={currency}
                                        period={t('pricingBannerPlan')}
                                        styles={{
                                            container: styles.price,
                                            currency: styles.priceCurrency,
                                            value: styles.priceValue,
                                            period: styles.pricePeriod,
                                        }}
                                    />
                                )}
                            </>
                        ),
                        link: <CustomLink href={{ pathname: 'pricing' }} />,
                        br: <DeskoptBr />,
                    }}
                    ns="pages/homepage/2024/v1/index"
                />
            </p>
            <CTAStartFreeCreateAccount
                params={{ version: 'centered' }}
                label={t('pricingBannerButton')}
                color={'black'}
            />
        </aside>
    );
};

export default PricingBanner;
